<template>
    <h1>
        Login
    </h1>
    
    <div>
        <div>
            <input
                type="email"
                placeholder="Email"
                autocomplete="username"
                v-model="email">
        </div>
        <div>
            <input
                type="password"
                placeholder="Passwort"
                autocomplete="current-password"
                v-model="paswd">
        </div>
        <div>
            <p class="error">
                {{error}}
            </p>
        </div>
        <div>
            <button v-on:click="login()">
                <span class="material-icons-round">
                login
                </span>
                <span>
                    Anmelden
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    methods: {
        login(){
            let formData = new FormData;
            formData.append('ben', this.email);
            formData.append('pas', this.paswd);

            var req = new XMLHttpRequest;
            req.open('post', '/api/v1/login/', true);
            req.onload = () => {
                let data = JSON.parse(req.responseText);
                if(data.success){
                    if(!data.authorized){
                        this.error = data.reason;
                    }else{
                        this.$emit('changeLoginStatus', true);
                        this.$router.push('/');
                    }
                }
            }
            req.send(formData);
        },
        verifyUser(){
            axios.get('/api/v1/login/status/')
            .then(response => {
                if(response.data.success){
                    if(response.data.authorized){
                        this.$router.push('/');
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    },
    data(){
        return {
            error: '',
            email: '',
            paswd: ''
        }
    },
    created(){
        this.verifyUser();
    }
}
</script>

<style scoped>
.error{
    color: #f00;
}
</style>